<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement space clear-details-page">
            <div class="container-960 bg-white p-md-4 p-2">
                <div v-if="loading" class="loading"><skeletonLoader /></div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    @click="() => goBack()"></button>
                <div class="newsLabel" v-if="Object.keys(showNews).length > 0"
                    :class="(Object.keys(showNews).length > 0) ? 'show' : ''">
                    <img class="d-block radius-5 mb-4 w-100 h-100" :src="showNews.image" alt="News" title="News Image" />
                        <div class="p-4 pt-0 border-0">
                            <!-- <div class="d-flex align-items-center justify-content-between modalHeader py-3">
                                <ul class="d-flex align-items-center mb-0">
                                    <li><a href="javascript:void(0)" class="d-flex me-3">
                                            <vue-feather size="23px" type="share-2"></vue-feather>
                                        </a></li>
                                    <li><a href="javascript:void(0)" class="d-flex ">
                                            <vue-feather size="23px" type="printer"></vue-feather>
                                        </a></li>
                                </ul>
                            </div> -->
                            <ul class="datTime d-flex align-items-center mb-0">
                                <li class="me-4"><a class="green" href="javascript:void(0)">{{showNews.source}}</a></li>
                                <li>{{formatNow(showNews.published,'now',0)}}</li>
                            </ul>
                            <h2 class="medium py-2">{{showNews.title}}</h2>
                            <p v-html="showNews.description"></p>
                        </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
import skeletonLoader from "../../components/shared/skeletonLoader";
import { useMainStore } from "@/store";
import moment from "moment";
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            showNews: {},
            loading: false,
        }
    },
    methods: {
        goBack(){
             // if(this.$router.options.history.state.back.split('?')[0] == '/all-news'){
             //    let path = this.$router.options.history.state.back.split('?')[0]
             //    let query = this.$router.options.history.state.back.split('?')[1]
             //    this.$router.replace({path : path,query:{type : query.split('&')[0].split('=')[1],news:query.split('&')[1].split('=')[1],from:'back',id:this.$route.params.id}})
             // }else{
             // }
                this.$router.back()
        },
        formatNow(date, format, offset) {
            if (!date) return 'N/A'
            let d = moment(date, 'YYYY-MM-DD HH:mm:ss')
            let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
            d.add(tz, 'minutes')
            return d.fromNow()
        },
        calnewsDetail(id) {
            let form = {
                '_id': id
            }
            this.store
                .callNewsDetails(form, false)
                .then((response) => {
                    this.showNews = response
                    this.loading = false
                })
                .catch(() => this.loading = false);
        },
    },
    created() {
        if (this.$route.params.id) {
            this.loading = true
            this.calnewsDetail(this.$route.params.id);
        } else {
            this.$router.push('/news')
        }
    },
    components: {
        bannerSlider: bannerslider,
        skeletonLoader,
    },
}
</script>